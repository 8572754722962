<template>
    <div :is="layout">
        <v-container fluid>
            <v-row>
                <v-col cols="3">
                    <v-text-field @keypress.enter="doGet(1)" v-model="search_info.name"
                                  :label="$t('flight_information.name')"></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-btn @click="doGet(1)" large color="secondary2">{{$t('search')}}</v-btn>
                </v-col>
            </v-row>
            <!-- <v-data-table
                    :server-items-length="list_count"
                    :headers="headers"
                    :items="list"
                    :items-per-page="10"
                    :loading-text="$t('data_loading')"
                    :loading="table_loading"
                    @pagination="changePagination"
                    class="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar
                            flat
                    >
                        <v-toolbar-title>{{$t('flight_information.title')}}</v-toolbar-title>
                        <v-divider
                                class="mx-4"
                                inset
                                vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-btn @click="$router.push({name:'flightInformationPreview'})">preview view</v-btn>
                    </v-toolbar>
                </template> -->
                <!-- <template v-slot:item.leave_time="{item}">
                    {{item.leave_date}} {{item.leave_time}}
                </template>
                <template v-slot:item.land_time="{item}">
                    {{item.land_date}} {{item.land_time}}
                </template>
                <template v-slot:item.land_use_time="{item}">
                    {{item.land_use_time}}秒
                </template>
                <template v-slot:item.leave_use_time="{item}">
                    {{item.leave_use_time}}秒
                </template> -->
                <!-- <template v-slot:item.operation="{item}">
                    <v-btn :to="{name: 'controlRegion', params: {id: item.id}}" small color="primary" text>{{$t('flight_information.control')}}</v-btn>
                    <v-btn :to="{name: 'flightInformationPreview3d', params: {id: item.id}}" small color="primary" text>{{$t('flight_information.preview')}}</v-btn>
                    <v-btn @click="edit_open(item)" small color="primary" text>{{$t('edit')}}</v-btn> -->
<!--                    <v-btn @click="del(item)" small color="primary" text>删除</v-btn>-->
                <!-- </template>
            </v-data-table> -->
            <table-list :list="list" :listCount="list_count" @change="changePagination">
            <template #icon>
                <i class="airport icon-feihangqingbaoqu1 icon_font"></i>
            </template>
            <template  #item="{item}">
            <div style="margin-bottom:10px">{{item.name}}</div>
            <div class="small_font">{{$t('flight_information.abbreviation')}}: {{item.abbreviation}}</div>
            <div class="small_font">{{$t('flight_information.min_vertical')}}: {{item.min_vertical}}</div>
             <div class="small_font">{{$t('flight_information.max_vertical')}}: {{item.max_vertical}}</div>
            </template>
            <template #active="{item}">
                <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                        color="secondary"
                        outlined
                        text
                        @click="edit_open(item)"
                        v-on="on"  x-small fab>
                        <i class="airport small-font icon-bianji "
                        style='font-size: 18px !important; color: #b5bac1'></i>
                    </v-btn>
                </template>
                <span>{{$t('edit')}}</span>
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                        color="secondary"
                        text
                        v-on="on"
                        outlined
                        :to="{name: 'controlRegion', params: {id: item.id}}"
                        class="ml-2" depressed x-small fab>
                        <i class="airport small-font icon-guanzhiqu"
                        style='font-size: 18px !important; color: #b5bac1'></i>
                    </v-btn>
                </template>
                <span>{{$t('flight_information.control')}}</span>
            </v-tooltip>
                  <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                        color="secondary"
                        text
                        v-on="on"
                        outlined
                       :to="{name:'flightInformationPreview'}"
                        class="ml-2" depressed x-small fab>
                        <i class="airport small-font icon-preview"
                        style='font-size: 18px !important; color: #b5bac1'></i>
                    </v-btn>
                </template>
                <span>{{$t('flight_information.preview')}}</span>
            </v-tooltip>
                  <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                        color="secondary"
                        text
                        v-on="on"
                        outlined
                        :to="{name: 'flightInformationPreview3d', params: {id: item.id}}"
                        class="ml-2" depressed x-small fab>
                        <i class="airport small-font icon-Dyulan-copy"
                        style='font-size: 18px !important; color: #b5bac1'></i>
                    </v-btn>
                </template>
                <span>{{$t('flight_information.Preview3d')}}</span>
            </v-tooltip>
            </template>
        </table-list>
        </v-container>
        <v-btn @click="add_open" color="primary" fab class="float-btn">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-dialog v-model="dialog" width="800px">
            <v-card color="secondary">
                <v-card-title>{{$t(edit_mode ? 'flight.edit_flight' : 'flight.add_flight')}}</v-card-title>
                <v-container class="pd-30">
                    <v-row>
                        <v-col cols="8">
                            <v-text-field v-model="info.name" :label="$t('flight_information.name')"></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field v-model="info.abbreviation"
                                          :label="$t('flight_information.abbreviation')"></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field type="number" v-model="info.min_vertical"
                                          :label="$t('flight_information.min_vertical')"></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field type="number" v-model="info.max_vertical"
                                          :label="$t('flight_information.max_vertical')"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="submit" color="accent">{{$t('confirm')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import {get_list, add_info, edit_info} from "../../../api/flight_infomartion";
    import TableList from '@/components/TableList'
    export default {
        name: 'flightInformationList',
        components:{TableList},
        data: () => ({
            list: [],
            edit_mode: false,
            search_info: {
                pageindex: 1,
                pagesize: 10,
                name: ''
            },
            table_loading: false,
            dialog: false,
            list_count: 0,
            info: {
              name:'',
              abbreviation:'',
              min_vertical:'',
              max_vertical:'',
            //   create_time:'',
            //   update_time:'',
            },
            add_loading: false,
            layout: null
        }),
        methods: {
            changePagination(data) {

                this.search_info.pageindex = data.page
                // this.search_info.pagesize = data.itemsPerPage
                this.doGet()
            },
            // del(data) {
            //     this.$confirm({
            //         title: '确认删除',
            //         content: '确认删除航班吗?',
            //         okText: '确认',
            //         cancelText: '取消'
            //     })
            //         .then((dialog) => {
            //             requestFlight.del(data.id).then(res => {
            //                 this.doGet()
            //             })
            //         })
            // },
            add_open() {
                this.edit_mode = false
                for (let i in this.info) {
                    // reset value
                    this.info[i] = ''
                }
                if ('id' in this.info) {
                    delete this.info.id
                }
                this.dialog = true
            },
            edit_open(data) {
                this.edit_mode = true
                for (let i in this.info) {
                    this.info[i] = data[i]
                }
                this.info.id = data.id
                this.dialog = true
            },
            doGet(pageindex = this.search_info.pageindex) {
                this.search_info.pageindex = pageindex
                this.table_loading = true
                const closeLoading = this.$loading()
                get_list(this.search_info).then(res => {
                    this.list = res.results
                    this.list_count = Math.ceil(res.count/this.search_info.pagesize)
                }).finally(() => {
                    this.table_loading = false
                  closeLoading()
                })
            },
            submit() {
                this.add_loading = true
                if (this.edit_mode) {
                    edit_info(this.info).then(res => {
                        this.$toast({
                            title: this.$t('success'),
                            message: this.$t('flight_information.edit_success_flightinfolist')
                        })
                        this.dialog = false
                    }).finally(() => {
                        this.add_loading = false
                        this.doGet()
                    })
                } else {
                    add_info(this.info).then(res => {
                        this.$toast({
                            title: this.$t('success'),
                            message:this.$t('flight_information.add__success_flightinfolist')
                        })
                        this.dialog = false
                    }).finally(() => {
                        this.add_loading = false
                        this.doGet()
                    })
                }
            }
        },
        mounted() {
            this.doGet()
            this.layout = this.$route.meta.use_layout
        },
        computed: {
            headers() {
                return [
                    {
                        text: this.$t('flight_information.name'),
                        align: 'center',
                        value: 'name',
                        sortable: false
                    },
                    {
                        text: this.$t('flight_information.abbreviation'),
                        align: 'center',
                        value: 'abbreviation',
                        sortable: false
                    },
                    {
                        text: this.$t('flight_information.min_vertical'),
                        align: 'center',
                        value: 'min_vertical',
                        sortable: false
                    },
                    {
                        text: this.$t('flight_information.max_vertical'),
                        align: 'center',
                        value: 'max_vertical',
                        sortable: false
                    },
                    {
                        text: this.$t('flight_information.create_time'),
                        align: 'center',
                        value: 'create_time',
                        sortable: false
                    },
                    {
                        text: this.$t('flight_information.update_time'),
                        align: 'center',
                        value: 'update_time',
                        sortable: false
                    },
                    {
                        text: this.$t('flight_information.operation'),
                        align: 'center',
                        value: 'operation',
                        sortable: false
                    },
                ]
            }
        },

    }
</script>
<style lang="stylus" scoped>
    @import '~@/assets/css/global.styl'
.small_font
        font-size 14px !important
        padding 2px 0px
.icon_font
        font-size 120px !important
</style>
