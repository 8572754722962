import { request } from '@/utils/'

// 获取飞行情报区列表
export const get_list = params => request.get('/api/flight_information/', { params })

// 添加飞行情报区
export const add_info = data => request.post('/api/flight_information/', data);

// 添加飞行情报区
export const edit_info = data => request.patch(`/api/flight_information/${data.id}/`, data);
